@font-face {
  font-family: 'Triade-Upright';
  src: url('components/fonts/Triade-Upright.eot');
  src: url('components/fonts/Triade-Upright.eot') format('embedded-opentype'),
       url('components/fonts/Triade-Upright.woff2') format('woff2'),
       url('components/fonts/Triade-Upright.woff') format('woff'),
       url('components/fonts/Triade-Upright.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

.container{
    margin-top: 25px;
}

.signform{
    border: 2px solid black;
}

.fields input{
    width: 200px;
}

h3{
    font-size: 30px;
}

.logo-sign{
    position: relative;
}

.vertical-border{
    width:5px;
    height:200px;
    border-left: 4px solid #033859; 
    padding-right: 30px;
}

table, tr, td, a{
    font-size: 12pt;
}

.button-clipboard{
    -webkit-user-select: none;
}

